import { 
  Modal, 
  Form, 
  Tab,
  Tabs,
  Alert
} from "react-bootstrap";
import { ReactComponent as Copy } from "../../assets/images/copy.svg";
import ButtonWithIcon from "../../widgets/ButtonWithIcon/ButtonWithIcon";
import React, { useRef, useState } from 'react';
import SocialMediaTab from "../common/SocialMediaTab";
import Button from "../../widgets/Button/Button"
import '../../pages/Opportunities/style.scss'

function ShareModal(props) {
  const [key, setKey] = useState('social_media');
  const [showAlert, setShowAlert] = useState(false);
  const alertRef = useRef(null);
  const path = window.location.origin;

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const copyLink = ()=>{
    navigator.clipboard.writeText(path + '/opportunities/' + props.oppId)
    .then(function() {
      setShowAlert(true)

      setTimeout(()=>{
        // alertRef.current.remove()
      },2000)
      
    })
    .catch(function(error) {
      console.error("Failed to copy text: ", error);
    });
  }

  return ( 
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="share_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Share
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex-between">
            <Form.Control 
              className="mr-10"
              defaultValue={path + '/opportunities/' + props.oppId}
              style={{
                backgroundColor: "rgba(244, 245, 247, 0.64)",
                border: "none"
              }}
              placeholder="joinjunity.com/ref/public/opportunity..."
            />
            <ButtonWithIcon
              text="Copy"
              icon={<Copy/>}
              className="primary btn-copy align_left"
              onClick={copyLink}
            />
          </div>
          <div className="mg-20">
            <Tabs
              id="share_modal"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="social_media" title="Social Media">
                <SocialMediaTab title={props.oppTitle} shareUrl={props.oppId} />
              </Tab>
              <Tab eventKey="send_to_email" title="Send To Email">
                <div>
                  Who should we send this to?
                </div>
                <Form.Control
                  placeholder="Enter email address(es)"
                  style={{
                    backgroundColor: "rgba(244, 245, 247, 0.64)",
                    border: "none"
                  }}
                  className="mv-10"
                />
                <p className="input-sub-text">
                  Separate multiple emails with comma(,)
                </p>
                <Button
                  text="Send to Email"
                  type="primary long"
                />
              </Tab>
            </Tabs>
          </div>
        </Modal.Body>
      </Modal>
      <Alert 
        variant="success" 
        show={showAlert} 
        onClose={handleCloseAlert} 
        dismissible 
        ref={alertRef}>
        <p className="m-0 fw-bold">
          Copied link to clipboard
        </p>
      </Alert>
    </>
  );
}

export default ShareModal;