import React, {useState, useEffect} from "react";
import CommonTopSection from "../../components/common/TopSection";
import './style.scss'
import { FilterContext } from "../../components/common/ExplorePagesWrapper";


const PageTemplate = ({children})=> {
  const [search, setSearch] = useState(null)
  const [locations, setLocations] = useState([])

  const [searchFilters, setSearchFilters] = useState({
    'locations': locations,
    'search': search
  });

  useEffect(() => {
    setSearchFilters({
      'locations': locations,
      'search': search
    })
  }, [locations, search])
  
  return ( 
    <div className="page_wrapper">
      <CommonTopSection/>
      <FilterContext.Provider
        value={{
          searchFilters, 
          setSearchFilters,
          setSearch,
          setLocations
        }}
      >
        {children}
      </FilterContext.Provider>
    </div>
  );
}

export default PageTemplate;

