import { useEffect, useState } from "react";
import { GetCategoryWithInterests } from "../../services/query/categories";
import { CustomSelect } from "../../widgets/CustomSelect/CustomSelect";

function FilterDropdowns({ setFilter }) {
  const [categories, setCategories] = useState([]);
  // const [interests, setInterests] = useState([]);
  const oppCategories = ['Education', 'Employment']
  const datePosted = [
    'Today',
    'Last 48 Hours',
    'Last 7 Days',
    'Last 14 Days',
    'Last 29 Days',
  ];

  const appDeadline = [
    'Today',
    'Next 48 Hours',
    'Next 7 Days',
    'Next 14 Days',
    'Next 29 Days',
  ];

  const getCategories = async()=>{
    const catsInts = await GetCategoryWithInterests()
    setCategories(catsInts.map((item)=>item.name))
    const allInterests = [];
    catsInts.forEach(category => {
      category.interests.forEach(interest => {
        allInterests.push(interest.name);
      });
    });

    // setInterests(allInterests);
  }

  useEffect(() => {
    getCategories()
  }, []);

  return ( 
    <>
      <CustomSelect
        name="category"
        title="Opportunity Type"
        items={oppCategories}
        onChange={setFilter}
      />
      <CustomSelect
        name="interests"
        title="Interests"
        items={categories}
        objectKey="id"
        onChange={setFilter}
      />
      <CustomSelect
        name="datePosted"
        title="Date Posted"
        items={datePosted}
        onChange={setFilter}
      />
      <CustomSelect
        name="appDeadline"
        title="Application Deadline"
        items={appDeadline}
        onChange={setFilter}
      />
    </>
  );
}

export default FilterDropdowns;