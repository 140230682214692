import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { ReactComponent as Times} from '../../assets/images/times.svg';
import { City } from "country-state-city";
import {getCities} from "../../utils/helpers";

export const SelectedLocation = ({name, onRemove})=>{
    
    return (
        <div 
            className='selected-location d-flex align-items-center'
            style={{
                padding: '7px 12px',
                borderRadius: 8,
                gap: 8,
                background: 'linear-gradient(0deg, #FFE3B3, #FFE3B3),linear-gradient(0deg, #FFC768, #FFC768)',
                border: '1px solid #FFC768',
                fontFamily: "'Manrope', sans-serif",
                fontSize: 12,
                fontWeight: 700,
                letterSpacing: '0em',
                textAlign: 'left',
                color: '#554223',
                whiteSpace: 'nowrap'
            }}
        >
            <span>{name}</span>
            <Times 
                className='cursor-pointer'
                onClick={() => onRemove(name)}
            />
        </div>
    )
}

export const LocationSearchInput = ({setLocations})=>{
    const [filteredLocations, setFilteredLocations] = useState([]);
    const [places, setPlaces] = useState([]);
    const ref = useRef(null);
    const formRef = useRef(null)
    const modifiedStates = getCities({
        keys: ["name"],
        requiresAll: false,
        returnObj: false,
        sort: true
    });
    
    const onChange = (e)=>{
        const locations = modifiedStates.filter((elt) => elt?.toLowerCase().startsWith(e.target.value.toLowerCase()));
        setFilteredLocations(locations)
    }

    const onLocationSelect = (place)=>{
        setPlaces(prev => [...prev, place]);
        // setFilteredLocations(prev => prev.filter((elt) => elt !== place));
        formRef.current.value = ''
        formRef.current.focus()
        setFilteredLocations([])
    }

    const removeLocation = (place)=>{
        let newPlaces = places.filter((elt) => elt !== place);
        setPlaces(newPlaces);
        formRef.current.focus()
    }

    useEffect(() => {
      setLocations(places)
    }, [places, setLocations])

    return (
        <div style={{width: 516, maxWidth: '100%'}}>
            <label className='custom-form-label mb-2'>Location(s)</label>
            <div ref={ref}
                className='input-container d-flex position-relative'
                style={{flexWrap: 'wrap'}}>
                <div 
                    className='selected-locations d-flex'
                    style={{gap: 10, flexWrap: 'wrap'}}>
                    {places?.map((place, index)=>(
                        <SelectedLocation 
                            name={place} 
                            onRemove={removeLocation} 
                            key={`${place}-${index}`}/>
                    ))}
                </div>
                <Form.Control
                    ref={formRef}
                    placeholder="Start typing..."
                    className='custom-form-input'
                    style={{width: 'fit-content', maxWidth: '100%'}}
                    onChange={onChange}
                />
                {filteredLocations?.length ?
                <div 
                    className='position-absolute w-100 d-flex flex-column bg-white filtered-locations'
                    style={{
                        top: ref.current?.offsetHeight + 5,
                        left: 0,
                        maxHeight: 270,
                        overflowY: 'auto',
                    }}>
                        {filteredLocations?.map((place, index)=>(
                            <span 
                                key={`${place}-${index}`}
                                className='cursor-pointer filtered-location'
                                onClick={()=>{onLocationSelect(place)}}
                                >{place}</span>
                        ))}
                </div>
                : null}
            </div>
        </div>
    );
}
