import React, { useEffect, useState } from 'react'
import "./style.scss"
import { Link, useNavigate } from "react-router-dom";
import { OpportunityCard } from '../../../components/common/Cards';
import {axiosClient} from "../../../libs/axiosClient";
import { ReactComponent as EmptyState } from '../../../assets/images/no-submissions.svg';


function OverviewTab({resource, resourceID}) {
  const [opps, setOpps] = useState([])
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  
  const getOpportunities = async () => {
    setLoading(true)
    try {
      const resp = await axiosClient.post("/opportunities", {
        limit: 20,
        review: true,
        filter: {schoolID: {eq: resourceID},expiry_date: { ge: new Date() }},
        fields: [
          "id",
          "description",
          "name",
          "category",
          "createdAt",
          "resourceID",
          "schoolID",
          "state",
          "expiry_date"
        ]
      });
      setLoading(false)
      const data = resp?.data?.data;
      setOpps(data);
    } catch (err) {
      setLoading(false)
      console.error(err);
    }
  }

  useEffect(() => {
    getOpportunities()
  }, [resource])

  const handleSelect = (oppId)=>{
    return navigate(`/opportunities/${oppId}`)
  }
  
  return ( 
    <>
      <div className="resource_overview_tab">
        <h6 className="resource-welcome-message m-0">
          Welcome to {resource?.name}
        </h6>
        <p className="mt-10 resource-detail-description">
          {resource?.about}
        </p>
        <div className="main_content mt-40">
          {loading === true && <div className="d-flex justify-center">
              <div className="snippet" data-title="dot-pulse">
                <div className="stage">
                  <div className="dot-pulse"></div>
                </div>
              </div>
          </div>}
          {!loading && opps?.length
              ? (
                  <>
                    <div className="d-flex justify-content-between align-items-center">
                      <h6 className="heading">
                        Top Opportunities
                      </h6>
                      <Link
                          to={'/opportunities'}
                          className={'link-show-all'}
                      >
                        Show all
                      </Link>
                    </div>
                    <div
                        className="d-flex custom-flex-wrap gap-2 justify-content-center justify-content-md-start"
                    >
                      {opps?.map((opp, index) =>
                        (<div 
                          className='cursor-pointer' 
                          onClick={()=>{handleSelect(opp?.id)}}>
                          <OpportunityCard
                              opp={opp}
                              index={index}
                          />
                        </div>)
                      )}
                    </div>
                  </>
              ) : null }
          {!loading && !opps?.length ? 
            (
                  <div className='h-100 py-5 d-flex justify-content-center align-items-center'>
                    <div className="d-flex flex-column">
                      <EmptyState />
                      <p className="text-center">No opportunities found</p>
                    </div>
                  </div>
            )
            : null
          }

        </div>
      </div>
    </>
  );
}

export default OverviewTab;