import React, { useState, useEffect } from 'react';
import { 
  BoxArrowUpRight,
  GeoAlt, 
  Person
} from 'react-bootstrap-icons';
import { Link, useLocation } from 'react-router-dom';
import { GetEducatorsBySchoolID } from '../../services/query/educators';
import PageTemplate from '../../templates/PageTemplate';
import './style.scss'
import { GetSingleSchool } from '../../services/query/school';
import { ReactComponent as EmptyState } from '../../assets/images/no-submissions.svg';

export function MentorsProfilePage() {
  const search = new URLSearchParams(useLocation().search)
  const organizationID = search.get('id')
  const [organization, setOrganization] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (organizationID && !Object.keys(organization).length){
      setLoading(true)
      GetSingleSchool(organizationID)
      .then(async(data)=>{
        let mentors = await GetEducatorsBySchoolID(data?.id)
        setLoading(false)
        setOrganization({...data, mentors})
      })
      .catch((error)=>{
        setLoading(false)
      })
    }
  }, [])

  return ( 
    <PageTemplate>
      <div className="resource_profile mentors_profile">
        <div className="resource_profile_main">
        {loading === true && <div className="d-flex justify-center">
            <div className="snippet" data-title="dot-pulse">
              <div className="stage">
                <div className="dot-pulse"></div>
              </div>
            </div>
        </div>}
        {!loading && Object.keys(organization).length 
        ? <>
          <div className="d-flex justify-content-between align-items-start">
            <div className="d-flex align-items-start custom-flex-wrap gap-2">
              <img
                src={organization?.image ||
                  require("../../assets/images/microsoft.png")
                }
                alt={organization?.name}
                style={{height: 42, width: 42}}
                className='object-fit-cover rounded-circle'
              />
              <div className="d-flex flex-column">
                <h5 className='opportunity-detail-owner m-0'>
                  {organization?.name}
                </h5>
                <div className="d-flex align-items-center mentor-address gap-2">
                  <GeoAlt size={13} fill="#969CA5" style={{marginLeft: -2}}/>
                  <h5 className='opportunity-detail-state'>
                    {organization?.address?.split(',')[1] || ""}
                  </h5>
                </div>
              </div>
              <div className="d-flex align-items-center resource-connections ms-2 mt-2 mt-sm-1">
                <div className='rounded-pill resource-connections-icon'>
                  <i className='fas fa-chain fa-fw text-white'></i>
                </div>
                <div className="align-items ml-10">
                  Connected to
                  <span className='ps-1 resource-connections-count'>
                    {organization?.connections?.length || 0} 
                    <span className='ps-1'>
                      {organization?.connections?.length > 1 ? 'Youths' : 'Youth'}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            {organization?.website 
            ?
            (<a 
              href={organization?.website}
              className="btn-website"
              target={'_blank'}
              rel="noreferrer"
              >
                <BoxArrowUpRight/>
                Website 
              </a>)
              
            :
            (
              <Link 
              to={'/mentors'}
              className="btn-website"
            >
              <BoxArrowUpRight/>
              Website 
            </Link>)
            }
          </div>
          <div>
            <h5 className='resource-welcome-message mb-0 mt-3'>
              About {organization?.name}
            </h5>
            <p className='resource-detail-description mt-3'>
              {organization?.about}
            </p>
          </div>
          <h5 className="heading text-start mt-2">
            Mentors ({organization?.mentors?.length})
          </h5>
          <div 
            style={{overflowX: "auto"}}
            className="team_x_sect mt-20 flex-start"
          >
            {organization?.mentors?.length && organization?.mentors?.map((educator, index) =>
              <div className='educator-container text-center' key={`mentor-${index}`}>
                <div className='educator-container-image-container bg-light pb-0'>
                  {educator?.image 
                  ? (
                    <img 
                      src={educator?.image}
                      alt={educator?.name}
                      className="educator-container-image"
                    />
                  )
                  : (
                    <Person size={80}/>
                  )
                  }
                </div>
                <div className='educator-container-name'>
                  {educator?.name}
                </div>
              </div>
            )}
          </div>
        </>
        : null 
      }
      {!loading && !Object.keys(organization)?.length
          ? (
                <div className='h-100 py-5 d-flex justify-content-center align-items-center'>
                  <div className="d-flex flex-column">
                    <EmptyState />
                    <p className="text-center">Mentor not found</p>
                  </div>
                </div>
            )
          : null
        }
        </div>
      </div>
    </PageTemplate>
  );
}