import React, { createContext, useEffect, useState } from 'react'
import { NavigationBadges } from './NavigationBadges'
import SearchSection from './SearchSection'

export const FilterContext = createContext()

export const ExplorePagesWrapper = ({children})=>{
  const [search, setSearch] = useState(null)
  const [locations, setLocations] = useState([])

  const [searchFilters, setSearchFilters] = useState({
    'locations': locations,
    'search': search
  });

  useEffect(() => {
    setSearchFilters({
      'locations': locations,
      'search': search
    })
  }, [locations, search])
  
  
  return (
    <div className="public_opportunity">
      <div className="sticky-search-filters">
        <NavigationBadges/>
        <SearchSection setLocations={setLocations} onSearch={setSearch}/>
      </div>
      <div className='section_main mt-20 opps_sect pd-20'>
        <FilterContext.Provider
          value={{searchFilters, setSearchFilters, setSearch}}>
          {children}
        </FilterContext.Provider>
      </div>
    </div>
  )
}
