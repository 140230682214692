import React, { useState } from 'react';
// import { State }  from 'country-state-city';

import { 
  BoxArrowUpRight,
  Clock,
  Check2Circle,
  Upload,
  ChevronUp,
  ChevronDown,
  X,
  Calendar4Event,
  Bookmark
} from "react-bootstrap-icons";
import ApplyYouthModal from "./ApplyYouthModal";
import { calculateTime, formatDate } from '../../utils/helpers';
import { BadgeInput } from '../common/BadgeInput';
import {Link, useNavigate} from 'react-router-dom';
import ShareModal from './ShareModal';


function OpportunityDetails(props) {
  const {
    rightModal, 
    setRightModal,
    collapse,
    setCollapse,
    opp
  } = {...props}
  
  const [applyYouthModal, setApplyYouthModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);

  const navigate = useNavigate();

  const close = ()=>{
    navigate('/opportunities')
    setRightModal(false);
  }

  // const usStates = State.getStatesOfCountry('US');
  // const splittedResourceState = opp?.state?.length ? opp?.state[0]?.split(",") : "";
  // const resourceState = splittedResourceState?.length > 0 ? splittedResourceState[splittedResourceState?.length - 2]: '';
  // const state = usStates.find((elt) => elt.isoCode === resourceState.trim());

  return ( 
    <>
    {rightModal &&
      <div className="rightModal pd-20 shadow">
        <div className="top_section pb-4">
          <div className="w-100 d-flex justify-content-between align-items-center gap-1">
            <div className='d-flex align-items-center gap-1'>
              <img 
                src={opp?.school?.image || require("../../assets/images/microsoft.png")}
                width="42" 
                height="42"
                alt="Org Logo"
              />
              <div className='d-flex flex-column'>
                <h6 className='opportunity-detail-owner'>
                  {opp?.school?.name}
                </h6>
                <h6 className='opportunity-detail-state'>
                  {opp?.state[0]}
                </h6>
              </div>
            </div>
            <div className='d-flex align-items-center gap-2'>
              <Link 
                to={`/resource-profile?id=${opp?.school?.id}`}
                className='btn btn-view-profile'>
                  View Profile
                  <BoxArrowUpRight/>
              </Link>
              <div 
                className="close_btn flex-center cursor-pointer shadow-sm"
                onClick={close}
              >
                <X size={25}/>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="d-flex align-items-center gap-1 pt-4 border-top">
          <GeoAlt size={20} fill="#969CA5"/>
          <div className="ml-10" style={{color: "#969CA5"}}>
            {opp?.state[0]}
          </div>
        </div> */}
        <div className="opportunity-detail-name my-3">
          {opp?.name}
        </div>
        <div className="flex-start">
          <div className="mr-10 flex-start">
            <div className="align-items mr-10">
              <Calendar4Event fill="#515A6A" className="bold"/>
            </div>
            <div className="opportunity-detail-posted">
              Posted {calculateTime(opp?.createdAt)}
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="align-items mr-10">
              <Clock fill="#AA8545"/>
            </div>
            <div className='opportunity-detail-expiry'>
              Closes {formatDate(opp?.expiry_date)}
            </div>
          </div>
        </div>
        <div className="d-flex align-item-center justify-content-between gap-2 py-4">
          <button
            type='button'
            className='btn opportunity-detail-recommend-btn w-100'
            onClick={()=>setApplyYouthModal(true)}
          >
            Apply As a Youth
            <Check2Circle />
          </button>
          <button
            type='button'
            className='btn opportunity-detail-btn'
            onClick={() => setShareModal(true)}
          >
            <Upload size={25}/>
          </button>
          <button
            type='button'
            className='btn opportunity-detail-btn'
          >
            <Bookmark size={25}/>
          </button>
          {/* <button
            type='button'
            className='btn opportunity-detail-btn'
          >
            <ThreeDotsVertical size={25}/>
          </button> */}
        </div>

        <div className='border-top'>
          <div className="flex-between mt-20">
            <h5 className='heading m-0'>
              Opportunity Details
            </h5>
            <div onClick={() => setCollapse(!collapse)}>
              <ChevronUp style={{display: collapse ? "none" : "block"}}/>
              <ChevronDown style={{display: collapse ? "block" : "none"}}/>
            </div>
          </div>
          <div style={{display: collapse ? "none" : "block"}}>
            <div className="mt-20">
              <h6 className='subheading m-0'>
                Tags
              </h6>
              <div className="d-flex custom-flex-wrap align-items-center gap-2">
                <BadgeInput
                  name={"interests"}
                  value={opp?.category?.toLowerCase()}
                  label={opp?.category}
                  type={"success"}
                  checked={false}
                  clickable={false}
                />
                {opp?.interests?.map((elt, index) => 
                  <BadgeInput
                    name={"interests"}
                    value={elt.toLowerCase()}
                    label={elt}
                    key={`interest${index}`}
                    type={"light"}
                    checked={false}
                    clickable={false}
                  />
                )}
              </div>
            </div>
            <div className="mt-20">
              <h6 className='subheading'>
                Description
              </h6>
              <div className="mt-10 flex-start">
                <p className='opportunity-detail-description'>{opp?.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
    <ApplyYouthModal
      show={applyYouthModal}
      setShow={setApplyYouthModal}
    />
    <ShareModal
      show={shareModal}
      onHide={() => setShareModal(false)}
    />
    </>
  );
}

export default OpportunityDetails;