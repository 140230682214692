import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import "./style.scss";
import {axiosClient} from "../../../libs/axiosClient";

export function RedirectPage() {
  const [page, setPage] = useState([<p>Redirecting page...</p>]);
  const search = new URLSearchParams(useLocation().search);
  const id = search.get('id');
  const navigate = useNavigate();

  const errorPage = () => {
    return (
      <div className='center-item'>
        <p>Email verification link has expired.</p>
      </div>
    )
  }

  useEffect(() => {
    const handleSetDependencies = async () => {
      const resp = await fetch(`${process.env.REACT_APP_NODE_SERVER}/decode-token`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: id })
      })
      if (resp.status === 400) {
        setPage(errorPage);
      } else {
        const respData = await resp.json();
        try {
          const response = await axiosClient.post('/get-student-by-id', {
            id: respData.studentId,
            fields: ["id", "name", "email"]
          });
          const student = response.data?.data;
          navigate(`/verify?id=${student.id}&name=${student.name}&email=${student.email}&parentEmail=${respData.email}`);
        } catch (err) {
          console.error(err);
        }
      }
    }

    handleSetDependencies();
  }, [id]);

  return ( 
    <>{page}</>
  );
}
