import { ChevronDown, List } from 'react-bootstrap-icons';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import './style.scss'
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import NavLinksModal from './Mobile/NavLinksModal';


function CommonTopSection({setExplore}) {

  const [mobileModal, setMobileModal] = useState(false)

  return ( 
    <>
      <div className="common_top_sect flex-between sticky-top bg-white">
        <Link 
          to="/"
          className='flex-between'>
          <div className="top_section__img">
            <img src={
              require("../../assets/images/user_logo.png")
            }
            alt=""
            />
          </div>
          <div className="top_section__heading align-items">
            <span>Junity</span>
            <sup>TM</sup>
          </div>
        </Link>
        
        <div className='align-items login_btn gap-3'>
          <div className='align-items middle_text'>
            <Link to='/opportunities'>
              Explore
            </Link>
          </div>
          <DropdownButton 
            title={
              <>
                <span className='pe-2'>Log In</span>
                <ChevronDown />
              </>
            } 
            className="login_drop"
          >
            <Dropdown.Item href={process.env.REACT_APP_YOUTH_URL}>As a Youth</Dropdown.Item>
            <Dropdown.Item href={process.env.REACT_APP_RESOURCES_URL}>As a Resource Provider </Dropdown.Item>
            <Dropdown.Item href={process.env.REACT_APP_MENTORS_URL}>As a Mentor</Dropdown.Item>
          </DropdownButton>
        </div>
        <div 
          className='align-items mobile_nav_icon'
          onClick={() => setMobileModal(true)}
        >
          <List size={40}/>
        </div>
      </div>
      <NavLinksModal
        show={mobileModal}
        setShow={setMobileModal}
        setExplore={setExplore}
      />
    </>
  );
}

export default CommonTopSection;